import { useEffect, useContext } from "react";
import UserContext from "../../context/userContext";
import firebase from "firebase";
import { firebaseConfig } from "../../firebaseConfig";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const AuthProvider = ({ children }) => {
  const { setUserContext } = useContext(UserContext);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // The user is signed in
        const userProfileSnapshot = await firebase
          .database()
          .ref("users/" + user.uid)
          .once("value");
        const userProfile = userProfileSnapshot.val();

        // Merge auth user info and userProfile data
        const fullUserInfo = {
          ...userProfile,
        };

        setUserContext(fullUserInfo);
      } else {
        // The user is signed out
        setUserContext(null);
      }
    });
  }, [setUserContext]);

  return children;
};

export default AuthProvider;
