import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import GlobalStateContext from "./context/globalStateContext";
import UserContext from "./context/userContext";
import Router from "./router";
import i18n from "./translation";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "./components/AuthProvider";
import * as amplitude from '@amplitude/analytics-browser';

// Define your types here
interface IGlobalState {
  // define your global state shape here
  [key: string]: any;
}

interface IUser {
  // define your user shape here
  [key: string]: any;
}

const App = () => {
  const [user, setUserContext] = useState<IUser | null>(null);
  const [globalState, setGlobalState] = useState<IGlobalState | null>(null);
  
  useEffect(() => {
    amplitude.init('817a1b9f454ad4a8560b0b54f5f5c8f1');
    amplitude.track('Visited app');
  });

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
          <UserContext.Provider value={{ user, setUserContext }}>
            <AuthProvider>
              <Router/>
            </AuthProvider>
            <ToastContainer />
          </UserContext.Provider>
        </GlobalStateContext.Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
